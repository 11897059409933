// Generated by Framer (2add0ca)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Tab from "https://framerusercontent.com/modules/M42MM5QoUdAgIsIpdSJx/tzXSeR7bB4rZke2ocz0Z/VAMUDsO6f.js";
const TabFonts = getFonts(Tab);

const cycleOrder = ["OZgZKIP60", "XaHtFfGZd", "AOOqsjipe"];

const variantClassNames = {AOOqsjipe: "framer-v-15ux1l6", OZgZKIP60: "framer-v-13q9z8t", XaHtFfGZd: "framer-v-1b9ybrq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Tab 1": "OZgZKIP60", "Tab 2": "XaHtFfGZd", "Tab 3": "AOOqsjipe"};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tab1?: string; tab2?: string; tab3?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "OZgZKIP60", tab1: MSGp_zYf0 = "Tab 1", tab2: f72tcvvi2 = "Tab 2", tab3: GuQmBQnFI = "Tab 3", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "OZgZKIP60", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const tap85mkxl = activeVariantCallback(async (...args) => {
setVariant("OZgZKIP60")
})

const tap19j16x3 = activeVariantCallback(async (...args) => {
setVariant("XaHtFfGZd")
})

const tap1vpjufm = activeVariantCallback(async (...args) => {
setVariant("AOOqsjipe")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-G9wrV", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-13q9z8t", className)} data-framer-name={"Tab 1"} layoutDependency={layoutDependency} layoutId={"OZgZKIP60"} ref={ref} style={{...style}} {...addPropertyOverrides({AOOqsjipe: {"data-framer-name": "Tab 3"}, XaHtFfGZd: {"data-framer-name": "Tab 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-ale9mi-container"} layoutDependency={layoutDependency} layoutId={"Inhe54tPx-container"}><Tab height={"100%"} id={"Inhe54tPx"} layoutId={"Inhe54tPx"} title={MSGp_zYf0} variant={"Ho9BqSUe0"} width={"100%"} {...addPropertyOverrides({AOOqsjipe: {tap: tap85mkxl, variant: "ym0P2kopY"}, XaHtFfGZd: {tap: tap85mkxl, variant: "ym0P2kopY"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-xm4bt7-container"} layoutDependency={layoutDependency} layoutId={"L4t6CPUGt-container"}><Tab height={"100%"} id={"L4t6CPUGt"} layoutId={"L4t6CPUGt"} tap={tap19j16x3} title={f72tcvvi2} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({XaHtFfGZd: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-1k5pgio-container"} layoutDependency={layoutDependency} layoutId={"GB5V80ZMZ-container"}><Tab height={"100%"} id={"GB5V80ZMZ"} layoutId={"GB5V80ZMZ"} tap={tap1vpjufm} title={GuQmBQnFI} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({AOOqsjipe: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-G9wrV [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-G9wrV .framer-2z0zhc { display: block; }", ".framer-G9wrV .framer-13q9z8t { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-G9wrV .framer-ale9mi-container, .framer-G9wrV .framer-xm4bt7-container, .framer-G9wrV .framer-1k5pgio-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-G9wrV .framer-13q9z8t { gap: 0px; } .framer-G9wrV .framer-13q9z8t > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-G9wrV .framer-13q9z8t > :first-child { margin-left: 0px; } .framer-G9wrV .framer-13q9z8t > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 275.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"XaHtFfGZd":{"layout":["auto","auto"]},"AOOqsjipe":{"layout":["auto","auto"]}}}
 * @framerVariables {"MSGp_zYf0":"tab1","f72tcvvi2":"tab2","GuQmBQnFI":"tab3"}
 */
const FramereJhbt3jEk: React.ComponentType<Props> = withCSS(Component, css, "framer-G9wrV") as typeof Component;
export default FramereJhbt3jEk;

FramereJhbt3jEk.displayName = "Elements/Tabs Copy 2";

FramereJhbt3jEk.defaultProps = {height: 49, width: 275.5};

addPropertyControls(FramereJhbt3jEk, {variant: {options: ["OZgZKIP60", "XaHtFfGZd", "AOOqsjipe"], optionTitles: ["Tab 1", "Tab 2", "Tab 3"], title: "Variant", type: ControlType.Enum}, MSGp_zYf0: {defaultValue: "Tab 1", displayTextArea: false, title: "Tab 1", type: ControlType.String}, f72tcvvi2: {defaultValue: "Tab 2", displayTextArea: false, title: "Tab 2", type: ControlType.String}, GuQmBQnFI: {defaultValue: "Tab 3", displayTextArea: false, title: "Tab 3", type: ControlType.String}} as any)

addFonts(FramereJhbt3jEk, [...TabFonts])